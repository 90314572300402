// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
// import PropTypes from 'prop-types'
import { css } from 'glamor'

// import map from "lodash/map";
// import isUndefined from "lodash/isUndefined";

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
// import { Link } from 'gatsby'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import StandardPage from '../components/StandardPage'

import seoHelper from '../methods/seoHelper'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
const pageData = {
  pageTitle: 'Schools For Good',
  nakedPageSlug: 'schools-for-good',
  pageAbstract:
    'Cambridge Innovative School is committed to community service. The school is a center of dedicated action for the good of people. We work together with many great organisations to improve the life conditions of people. We also have many unique programs of which our school students and teachers are an integral part.',
}

const seoData = seoHelper(pageData)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Styles
// ----------------------------------------------------------------------------
const pageStyles = css({
  '& strong': {
    fontWeight: 700,
    color: '#004195',
  },

  '& h1, h2, h3': {
    color: '#004195',
  },

  '& h1': {
    textTransform: 'uppercase',
    fontStyle: 'italic',
    borderTop: '4px solid #004394',
  },

  '& h3': {
    borderTop: '1.5px solid #004394',
  },
})
const pageStyle = pageStyles.toString()

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** Page */
const Page = () => (
  <StandardPage className={pageStyle} seoData={seoData}>
    <h1>Schools For Good</h1>
    <p>
      Cambridge Innovative School is committed to community service. The
      school is a center of dedicated action for the good of people. We work
      together with many great organisations to improve the life conditions of
      people. We also have many unique programs of which our school students and
      teachers are an integral part. Projects we are proud to be a part of –
    </p>
  
    <h3>Walk of Hope 2015-16</h3>
    <p>
        Walk of Hope was a padayatra for peace and harmony by the Manav Ekta Mission
        which covered 7500 kms from Kanyakumari to Kashmir. Led by Sri M, founder of Manav
        Ekta Mission, the journey spanned 16 months through 11 Indian states, bringing 
        together citizens from all walks of life. Traversing the varied landscape of India,
        it sought a significantly restored nation—fit enough to be bequeathed to our future generations.
     </p>
     <p>
        The Walk of Hope was flagged off on 12 January 2015, the birth anniversary of a 
        great prophet of interfaith harmony, Swami Vivekananda. Starting from the Gandhi
        Memorial Mandapam in Kanyakumari, it concluded in Srinagar, Jammu & Kashmir on 
        29 April 2016, reaching out to 10 million fellow Indians during the Walk.
      </p>
      <p>
        Learning Wings was involved intimately with this initiative, and it's 
        team persons were in the national co-ordinating team.
      </p>

      <h3>Satsang Vidyalaya</h3>
      <p>
        Satsang Vidyalaya is an educational initiative for under-privileged 
        children. Begun in 1999, Satsang Vidyalaya comprises of two schools 
        and over 200 students. The schools offer completely free education 
        to children from the age of 6 up to the ages of 15. In addition to 
        free schooling, Satsang Vidyalaya also provides a free mid-day lunch 
        for all students.
      </p>
      <p>
        Satsang Vidyalaya follows the state school syllabus as prescribed 
        by the Andhra Pradesh board. The school has 15 teachers on its rolls 
        across subjects such as Science, Mathematics, Social sciences, English, 
        and Languages. There is a strong focus on infrastructure with multiple 
        classrooms being built, advanced learning techniques, and teacher 
        training with the intention of providing students free yet un-compromised 
        education to help them progress in life. Learning Wings supports and works 
        with Satsang Vidyalaya to make quality education for the under-privileged 
        a possibility.
      </p>
      <h3>MyTree</h3>
      <p>
        MyTree is inspired by the Sanskrit word – Maitri – meaning amity. 
        Amity conforms to the concept of Manav Ekta (oneness of humanity) 
        propounded by Sri M, which is being implemented through the various 
        programs of the Mission. It is envisioned that by encouraging an 
        individual/community-led partnership to plant trees, a natural human 
        alliance will be formed to ensure a sustainable relationship with nature. 
        This amity or Mytree (derived from Maitri) is the crux of the initiative.
      </p>
      <p>
        Learning Wings' students regularly undertake MyTree drive in their respective 
        localities, and have, till day planted over 50,000 saplings.
      </p>

      <h3>Deaf & Dumb School</h3>
      <p>
        Deaf & Dumb School is an initiative to provide quality education to impaired 
        individuals with the goal of making them self-sufficient, independent and 
        productive individuals of society.
      </p>
      <p>
        Learning Wings students provide companionship and compassion to the learners 
        with their disability with regular visits and build a bond to support in all 
        possible areas. 
      </p>

      <h3>Digital Tibet</h3>
      <p>
        Digital Tibet is an initiative by the Tibetan Community and other volunteers 
        to preserve records of the Tibetan people. It's first project, "Tibet in Exile", 
        is a photo journal about the life of Tibetans after they exiled. The photo journal
        will help the younger generation of Tibetans understand and appreciate the history
        and origins of the Exiled Tibetan Community, and illustrate to them - as well as
        non Tibetans around the world—the extraordinary achievements of the early refugee community.
      </p>
      <p>
        This project is funded by National Geographic, and Dalai Lama has written the foreword of the 
        book. Learning Wings has supported this initiative by providing technical assistance. 
      </p>

      <h3>Goonj</h3>
      <p>
        Goonj is a non-governmental organisation based in Delhi, India which undertakes disaster 
        relief, humanitarian aid and community development in parts of 21 states across India. 
        It recycles discarded clothes and household goods into useful products for the poor, 
        such as sanitary napkins. It collects and delivers 1,000 tons of material every year 
        through a network of 500 volunteers and 250 partners. It also runs infrastructure and 
        local development projects in villages and slum areas. 
      </p>
       <p>
        Learning Wings is a partner and students regularly participate in activities with the 
        goal of procuring relief material for the under-privileged. 
      </p>

      <h3>The Hudli Project</h3>
      <p>
        The Hudli Project is an initiative designed to create employment in Hudli village. 
        It is supported by the Khadigram of Hudli Village. Poverty, deprivation and 
        unemployment are resulting in people leaving Hudli and moving to towns in search 
        of a better livelihood. The immediate goal of The Hudli Project is to create guaranteed 
        employment for at least 100 women for an entire year (or longer) by creating demand for 
        the pickles made by the Khadigram.
      </p>
      <p>
        Learning Wings supports The Hudli Project by purchasing merchandise created by women of the village.
      </p>

  </StandardPage>
)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default Page
